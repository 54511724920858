<template>
  <div class="surface-section w-full md:w-6 p-6 md:p-8">
    <div class="mb-5">
      <div class="text-900 text-3xl font-medium mb-3">Formulaire d'oubli de mot de passe</div>
    </div>
    <div>
      <form @submit.prevent="handleSubmit(!v$.$invalid)" class="p-fluid">
        <label for="email" :class="{'p-error':v$.email.$invalid && submitted}" class="block text-900 font-medium mb-2"><i class="fa-solid fa-envelope" /> {{ $t('email') }} *</label>
        <InputText id="email" v-model="v$.email.$model" type="text" :class="{'p-invalid':v$.email.$invalid && submitted}" class="w-full mb-3 p-3" />
        <div v-if="v$.email.$error && submitted" class="mb-2">
          <span id="email-error" v-for="(error, index) of v$.email.$errors" :key="index">
            <InlineMessage>{{error.$message}}</InlineMessage>
          </span>
        </div>

        <Button :label="$t('resetPassword')" type="submit" icon="fa-regular fa-user" class="w-full p-3"></Button>
      </form>
    </div>
<!--    <div style="position: fixed; bottom: 20px; left: 20px;">-->
<!--      <language-selection />-->
<!--    </div>-->
  </div>
  <div class="hidden md:block w-6 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/signin/signin.jpg') + ')' }"></div>
</template>

<script>
// import LanguageSelection from '@/components/LanguageSelection';
import Alert from '@/utils/Alert';
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";


export default {
  setup: () => ({ v$: useVuelidate() }),
  components : {
    // LanguageSelection,
  },
  data () {
    return {
      email: '',
      submitted: false
    }
  },
  validations() {
    return {
      email: {
        required: helpers.withMessage('Email requis', required),
        email: helpers.withMessage('Email non valide', email)
      },
    }
  },
  methods: {
    async handleSubmit (isFormValid) {
      this.submitted = true

      if (!isFormValid) {
        Alert.errorMessage(this, 'fieldRequired', {symbole: '(*)'})
        return
      }

      this.$store.state.misc.loading = true

      const payload = {
        email: this.email,
      }

      await this.$store.dispatch('auth/reset', payload)
        .catch((e) => {
          Alert.errorMessage(this, e.response.data.message)
          this.resetForm()
        })

      this.$toast.add({severity:'success', summary:'Succès', detail:'Un mail vous a été envoyé', life: 3000});
      this.$store.state.misc.loading = false
      this.$router.push({ name: 'Login' })

    },
    resetForm () {
      this.email = ''
      this.submitted = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>
